import { graphql } from 'gatsby'
import { css } from '@emotion/react'
import { TwitterTimelineEmbed } from 'react-twitter-embed'

import { ExternalLink } from '../EntryLink'

const TwitterWidget = (props) => {
  const { accountUserName } = props.twitterFeed || props

  return (
    <div
      css={css`
        background-color: white;
        height: 400px;
        overflow: auto;
        padding: 20px;
      `}
    >
      <TwitterTimelineEmbed sourceType="profile" screenName={accountUserName} />
      <ExternalLink
        css={css`
          display: none;
          visibility: hidden;
        `}
        url={`https://twitter.com/${accountUserName}`}
        linkText={`Tweets by ${accountUserName}`}
      />
    </div>
  )
}

export const query = graphql`
  fragment TwitterWidget on contentful_TwitterWidget {
    __typename
    sys {
      id
    }
    title
    twitterFeed {
      ...TwitterFeed
    }
  }
`

export default TwitterWidget
